'use client';

import Box from "@mui/material/Box";

import HomeFeatured from "./home-featured";
import { deleteCookie } from "cookies-next";
import HomeBestsellers from "./home-bestsellers";
import dynamic from "next/dynamic";
import Image from "src/components/image";
import HomeNewsletter from "./home-newsletter";

const HomeBanners = dynamic(() => import("./home-banners"), { ssr: false });
const HomeCategories = dynamic(() => import("./home-categories"), { ssr: false });

// ----------------------------------------------------------------------

export default async function HomeView() {
  deleteCookie("lastRoute");
  return (
    <Box>
      <HomeBanners />
      {/*<video
        autoPlay
        loop
        muted
        playsInline
        className="hero-video"
        style={{
          width: "100%",
          minHeight: '315px',
          maxHeight: "705px",
          objectPosition: "center",
          objectFit: "cover",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
        preload=""
      >
        <source src="/assets/hero/hero.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>*/}
      <HomeCategories />

      {/*<Image src="/assets/subbanners/row.png" alt="hero" sx={{width: '100%', height: '216px'}} />*/}
      {/*<HomeNewsletter />*/}
      <HomeBestsellers />

      <Box
        sx={{
          px: 0,
        }}
      >
        <HomeFeatured />
      </Box>
    </Box>
  );
}
