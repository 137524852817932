import axios, { AxiosRequestConfig } from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig], token?: string) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const authConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };

  const res = await axiosInstance.get(url, authConfig);
  return res.data;
};

export const blobFetcher = async (args: string | [string, AxiosRequestConfig], token?: string) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const authConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
    responseType: 'blob',
  };

  const res = await axiosInstance.get(url, authConfig);
  return res.data;
};

// ----------------------------------------------------------------------

export const poster = async (url: string, body: any, token?: string, config?: AxiosRequestConfig) => {
  try {
    const authConfig: AxiosRequestConfig = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const res = await axiosInstance.post(url, body, authConfig);
    return { data: res.data, status: res.status };
  } catch (error) {
    console.error('Error in poster:', error);
    throw error;
  }
};

export const patcher = async (url: string, body: any, token?: string, config?: AxiosRequestConfig) => {
  try {
    const authConfig: AxiosRequestConfig = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };

    const res = await axiosInstance.patch(url, body, authConfig);
    return { data: res.data, status: res.status };
  } catch (error) {
    console.error('Error in poster:', error);
    throw error;
  }
};

// ----------------------------------------------------------------------

// API Endpoints object
export const endpoints = {
  auth: {
    login: '/users/login',
    register: '/users/register',
    logout: '/users/logout',
    me: '/users/me',
  },
  product: {
    list: '/api/product/list',
    item: '/products',
    details: '/api/product/details',
    search: '/api/search/products',
  },
};
