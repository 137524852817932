"use client";
import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { useTranslations } from "next-intl";
import { useGetBestsellerProducts } from "src/api/product";
import ProductItem from "../product/product-item";
import { useResponsive } from "src/hooks/use-responsive";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMemo } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';

const HomeBestsellers = () => {
  const t = useTranslations();
  const mdUp = useResponsive("up", "md");
  const { products, productsLoading } = useGetBestsellerProducts();

  // Configure Swiper settings
  const swiperSettings = useMemo(() => ({
    breakpoints: {
      0: { slidesPerView: 1.85, spaceBetween: 10 },
      600: { slidesPerView: 2.5, spaceBetween: 15 },
      900: { slidesPerView: 4, spaceBetween: 20 },
      1200: { slidesPerView: 5, spaceBetween: 25 },
      1536: { slidesPerView: 6, spaceBetween: 30 },
    },
    loop: true,
    autoplay: {
      delay: mdUp ? 2500 : 4000,
      disableOnInteraction: false,
    },
    pagination: { clickable: true },
    modules: [Pagination, Autoplay],
  }), [mdUp]);

  if (!products || products.length < 1) {
    return null;
  }

  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 2, px: 0 }}>
      {productsLoading ? (
        <CircularProgress sx={{ mx: "auto !important" }} />
      ) : (
        <>
          <Typography textAlign="center" variant="h4" sx={{ mb: 6 }}>
            {t("bestsellers")}
          </Typography>
          <Box
            sx={{
              '.swiper-pagination-bullet': {
                pointerEvents: 'all'
              },
              '.swiper-pagination-bullet-active': {
                width: mdUp ? '8px' : '16px',
                height: mdUp ? '8px' : '16px',
              },
              "--swiper-pagination-color": (theme) => theme.palette.text.primary,
              "--swiper-pagination-bullet-inactive-color": (theme) => theme.palette.text.disabled,
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": mdUp ? '8px' : '14px',
              "--swiper-pagination-bullet-horizontal-gap": mdUp ? '8px' : '14px',
            }}
          >
            <Swiper {...swiperSettings} style={{ minHeight: '380px' }}>
              {products.map((_product, index) => (
                <SwiperSlide key={index} style={{ height: 'auto' }}>
                  <Box sx={{ maxWidth: 315, mb: 9, mx: 'auto' }}>
                    <ProductItem sx={{ height: '100%' }} product={_product} />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </>
      )}
    </Container>
  );
};

export default HomeBestsellers;
