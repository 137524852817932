import { ProductsFromAPI } from "src/api/product";
import { IProductItem } from "src/types/product";

export function mapProductFromAPIToIProductItem(productFromAPI: ProductsFromAPI): IProductItem {
    return {
      id: String(productFromAPI.id),
      sku: productFromAPI.ean, // Assuming 'ean' maps to 'sku'
      name: productFromAPI.name,
      generatedCategories: productFromAPI.generatedCategories,
      code: productFromAPI.productcategoryid, // Assuming this maps to 'code'
      price: parseFloat(productFromAPI.price), // Convert price from string to number
      taxes: 0, // Default taxes, can be calculated or set dynamically
      tags: [], // No direct mapping, can be populated as needed
      gender: '', // No direct mapping, can be populated as needed
      sizes: [`${productFromAPI.size_x}x${productFromAPI.size_y}x${productFromAPI.size_z}`], // Assuming this is a combined size
      size_x: productFromAPI.size_x, 
      size_y: productFromAPI.size_y,
      size_z: productFromAPI.size_z,
      publish: productFromAPI.modified, // Assuming 'mofidied' is the publish date
      coverUrl: productFromAPI.images[0] || '', // First image as coverUrl
      isBestseller: productFromAPI.isBestseller,
      images: productFromAPI.images, // Direct mapping
      colors: [], // No direct mapping, can be populated as needed
      quantity: productFromAPI.quantity,
      category: String(productFromAPI.categories[0] || ''), // Assuming the first category is primary
      available: productFromAPI.quantity, // Assuming 'available' is based on quantity
      totalSold: 0, // No direct mapping, default to 0
      description: productFromAPI.description, // Direct mapping
      totalRatings: 0, // No direct mapping, default to 0
      totalReviews: 0, // No direct mapping, default to 0
      weight: productFromAPI.weight,
      inventoryType: productFromAPI.unitName, // Mapping 'unitName' to 'inventoryType'
      subDescription: '', // No direct mapping, default empty string
      priceSale: null, // No direct mapping for sale price, default to null
      reviews: [], // No direct mapping for reviews, default empty array
      createdAt: new Date(), // Assuming the current date as default
      ratings: [], // No direct mapping, default empty array
      published: productFromAPI.published || false, 
      saleLabel: {
        enabled: false, // Default sale label
        content: '', // Default sale label content
      },
      newLabel: {
        enabled: false, // Default new label
        content: '', // Default new label content
      },
    };
  }