import useSWR from "swr";
import { useMemo } from "react";

import { fetcher, endpoints, poster } from "src/utils/axios";

import { IProductItem } from "src/types/product";
import { mapProductFromAPIToIProductItem } from "src/utils/product-processor";

// ----------------------------------------------------------------------

export type ProductsFromAPI = {
  categories: number[];
  categories_extended: number[];
  conditionName: string;
  currency: string;
  description: string;
  ean: string;
  foradults: boolean;
  groupName: string;
  id: number;
  images: string[];
  modified: string;
  name: string;
  price: string;
  productcategoryid: string;
  generatedCategories: string[];
  quantity: number;
  size_x: number;
  size_y: number;
  size_z: number;
  status: number;
  unitName: string;
  weight: number;
  isBestseller: boolean;
  published?: boolean;
};

export function useGetProducts({
  page = 1,
  limit = 20,
  category,
  sortBy,
  greaterThan,
  lessThan,
}: {
  page: number;
  limit: number;
  category?: string;
  sortBy?: string;
  greaterThan?: number;
  lessThan?: number;
}) {
  const URL = `/products/pagination?page=${page}&limit=${limit}${
    category ? `&category=${category}` : ""
  }${
    sortBy ? `&sortBy=${sortBy}` : ""
  }&greaterThan=${greaterThan}&lessThan=${lessThan}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const fixedProducts =
    data?.products.map((product: ProductsFromAPI) =>
      mapProductFromAPIToIProductItem(product)
    ) || [];
  const memoizedValue = useMemo(
    () => ({
      products: (fixedProducts as IProductItem[]) || [],
      productsLoading: isLoading,
      productsError: error,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !data?.products.length,
      pageCount: data?.pageCount || 0,
      itemsCount: data?.total || 0,
      currentPage: data?.page || 1,
    }),
    [
      data?.products,
      error,
      isLoading,
      isValidating,
      data?.pageCount,
      data?.total,
      data?.page,
    ]
  );

  return memoizedValue;
}

export function useGetRandomProducts({
  page = 1,
  limit = 20,
  category = undefined,
  excludeIds = undefined,
  isSmall = false,
}: {
  page?: number;
  limit?: number;
  category?: string;
  isSmall?: boolean;
  excludeIds?: string[];
}) {
  const URL = `/products/random?isSmall=${isSmall}${
    excludeIds !== undefined ? `&excludeIds=${excludeIds.join(",")}` : ""
  }`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const fixedProducts =
    data?.products.map((product: ProductsFromAPI) =>
      mapProductFromAPIToIProductItem(product)
    ) || [];
  const memoizedValue = useMemo(
    () => ({
      products: (fixedProducts as IProductItem[]) || [],
      productsLoading: isLoading,
      productsError: error,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !data?.products.length,
      pageCount: data?.pageCount || 0,
      itemsCount: data?.total || 0,
      currentPage: data?.page || 1,
    }),
    [
      data?.products,
      error,
      isLoading,
      isValidating,
      data?.pageCount,
      data?.total,
      data?.page,
    ]
  );

  return memoizedValue;
}

export function useGetBestsellerProducts() {
  const URL = `/products/bestsellers`;
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const fixedProducts =
    data?.products.map((product: ProductsFromAPI) =>
      mapProductFromAPIToIProductItem(product)
    ) || [];
  const memoizedValue = useMemo(
    () => ({
      products: (fixedProducts as IProductItem[]) || [],
      productsLoading: isLoading,
      productsError: error,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !data?.products.length,
      pageCount: data?.pageCount || 0,
      itemsCount: data?.total || 0,
      currentPage: data?.page || 1,
    }),
    [
      data?.products,
      error,
      isLoading,
      isValidating,
      data?.pageCount,
      data?.total,
      data?.page,
    ]
  );

  return memoizedValue;
}
// ----------------------------------------------------------------------

export function useGetProduct(productId: string) {
  const URL = `/products/${productId}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const fixedProduct = data ? mapProductFromAPIToIProductItem(data) : null;
  const memoizedValue = useMemo(
    () => ({
      product: fixedProduct as IProductItem,
      productLoading: isLoading,
      productError: error,
      productValidating: isValidating,
    }),
    [data?.product, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function useUpdateCartProducts(productIds: string[]) {
  const URL = `/products/by-ids`;
  try {
    const response = await poster(URL, { ids: productIds });
    return response.data;
  } catch (error) {
    console.error("Error in postProduct:", error);
    // throw error;
  }
}

// ----------------------------------------------------------------------

export function useSearchProducts(query: string) {
  const URL = query ? [`/search/products`, { params: { query } }] : "";

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  // console.log(data);

  const result =
    data && data.hits && data.hits.length > 1
      ? data.hits.map((hit: { _source: Object }) => hit._source)
      : [];

  const memoizedValue = useMemo(
    () => ({
      searchResults: (result as IProductItem[]) || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !result.length,
    }),
    [result, error, isLoading, isValidating]
  );

  return memoizedValue;
}
