import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { fCurrency } from 'src/utils/format-number';

import Label from 'src/components/label';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { ColorPreview } from 'src/components/color-utils';

import { IProductItem } from 'src/types/product';

import { useCheckoutContext } from '../checkout/context';
import { sendGAEvent } from '@next/third-parties/google';
import { useResponsive } from 'src/hooks/use-responsive';
import { SxProps } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  product: IProductItem;
  sx?: SxProps;
};

export default function ProductItem({ product, sx }: Props) {
  const { onAddToCart } = useCheckoutContext();
  const smUp = useResponsive('up', 'sm');

  const { id, images, weight, name, coverUrl, price, colors, available, sizes, priceSale, newLabel, saleLabel, size_x, size_y, size_z } =
    product;

  const linkTo = paths.product.details(id);

  const handleAddCart = async () => {
    const newProduct = {
      id,
      name,
      coverUrl,
      available,
      price,
      colors: [colors[0]],
      size: sizes[0],
      quantity: 1,
      size_x,
      size_y,
      size_z,
      weight,
      generatedCategories: product.generatedCategories,
    };
    try {
      onAddToCart(newProduct);
      window.gtag('event', 'add_to_cart', {
        items: [
          {
            item_id: newProduct.id,
            item_name: newProduct.name,
            price: newProduct.price,
            quantity: newProduct.quantity,
            currency: 'PLN'
          }
        ]
      });
    } catch (error) {
      console.error(error);
    }
  };

  const renderLabels = (newLabel.enabled || saleLabel.enabled) && (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ position: 'absolute', zIndex: 9, top: 16, right: 16 }}
    >
      {newLabel.enabled && (
        <Label variant="filled" color="info">
          {newLabel.content}
        </Label>
      )}
      {saleLabel.enabled && (
        <Label variant="filled" color="error">
          {saleLabel.content}
        </Label>
      )}
    </Stack>
  );

  const renderImg = (
    <Box sx={{ position: 'relative', p: 1 }}>
      {!!available && (
        <Fab
          color="primary"
          size="medium"
          className="add-cart-btn"
          id="add-cart-btn"
          onClick={handleAddCart}
          sx={{
            right: 16,
            bottom: 16,
            zIndex: 9,
            opacity: 0,
            position: 'absolute',
            transition: (theme) =>
              theme.transitions.create('all', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter,
              }),
          }}
        >
          <Iconify icon="solar:cart-plus-bold" width={24} />
        </Fab>
      )}

      <Tooltip title={!available && 'Out of stock'} placement="bottom-end">
        <Link
          sx={{
            '&:hover .hoverImage': {
              opacity: 1,
            },
          }}
          component={RouterLink} href={linkTo} color="inherit" variant="subtitle2" noWrap>
          <Image
            alt={name}
            src={coverUrl}
            ratio="1/1" sizes='(max-width: 900px) 67px, 87px'
            sx={{
              borderRadius: 1.5,
              cursor: 'pointer',
              userSelect: 'none',
              objectFit: 'cover',
              ...(!available && {
                opacity: 0.48,
                filter: 'grayscale(1)',
              }),
            }}
          />
          {images.length > 1 &&
            <Image
              alt={name}
              src={images[1]}
              className="hoverImage"
              ratio="1/1"
              sx={{
                position: 'absolute',
                top: 0,
                p: 1,
                left: 0,
                borderRadius: 5,
                cursor: 'pointer',
                transition: '0.5s',
                objectFit: 'cover',
                opacity: 0,
                ...(!available && {
                  // opacity: 0.48,
                  filter: 'grayscale(1)',
                }),
              }}
            />
          }
        </Link>
      </Tooltip>
    </Box>
  );
  const renderContent = (
    <Stack spacing={2.5} sx={{ p: 3, pt: 2 }}>
      <Link component={RouterLink} href={linkTo} noWrap={smUp} color="inherit" variant="subtitle2" sx={smUp ? {} : {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
      }}>
        {name}
      </Link>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ColorPreview colors={colors} />

        <Stack direction="row" spacing={0.5} sx={{ typography: 'subtitle1' }}>
          {priceSale && (
            <Box component="span" sx={{ color: 'text.disabled', textDecoration: 'line-through' }}>
              {fCurrency(priceSale)} zł
            </Box>
          )}

          <Box component="span">{fCurrency(price)} zł</Box>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Card
      sx={{
        '&:hover .add-cart-btn': {
          opacity: 1,
        },
        ...sx
      }}
    >
      {renderLabels}

      {renderImg}

      {renderContent}
    </Card>
  );
}
