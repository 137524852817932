'use client';

import { AuthGuard } from 'src/auth/guard';
import ThemeProvider from 'src/theme';
// ----------------------------------------------------------------------

import dynamic from 'next/dynamic';

const CookiesConsent = dynamic(() =>
  import('src/components/cookies-consent'), { ssr: false }
);

const DashboardLayout = dynamic(() =>
  import('src/layouts/dashboard'), { ssr: false }
);

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayoutComponent({ children }: Props) {
  return (
    <ThemeProvider>
      <CookiesConsent />
      <AuthGuard>
        <DashboardLayout>{children}</DashboardLayout>
      </AuthGuard>
    </ThemeProvider>
  );
}
