import Box, { BoxProps } from '@mui/material/Box';
import Pagination, { paginationClasses } from '@mui/material/Pagination';

import { IProductItem } from 'src/types/product';

import ProductItem from './product-item';
import { ProductItemSkeleton } from './product-skeleton';
import { Dispatch, SetStateAction, useEffect } from 'react';

// ----------------------------------------------------------------------

type Props = BoxProps & {
  products: IProductItem[];
  loading?: boolean;
  currentPage?: number;
  pageCount?: number;
  setCurrentClientPage: Dispatch<SetStateAction<number>>;
  disablePagination?: boolean;
  disableScrollToTop?: boolean;
};

export default function ProductList({ products,disablePagination, disableScrollToTop, setCurrentClientPage, loading, pageCount, currentPage, ...other }: Props) {
  const renderSkeleton = (
    <>
      {[...Array(16)].map((_, index) => (
        <ProductItemSkeleton key={index} />
      ))}
    </>
  );

  const renderList = (
    <>
      {products.map((product) => (
        <ProductItem key={product.id} product={product} />
      ))}
    </>
  );
  useEffect(() => {
    if (window && !disableScrollToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPage]);

  return (
    <>
        {((pageCount && pageCount > 1 && products.length > 0) || (!disablePagination && products.length !== 0)) ? (
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={(_, page) => setCurrentClientPage(page)}
          sx={{
            mb: 6,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      ) : null}
      <Box
        gap={{xs: 1, sm: 3}}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(2, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)',
        }}
        {...other}
      >
        {loading ? renderSkeleton : renderList}
      </Box>

      {((pageCount && pageCount > 1 && products.length > 0) || (!disablePagination && products.length !== 0)) ? (
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={(_, page) => setCurrentClientPage(page)}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      ) : null}
    </>
  );
}
