"use client";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { useTranslations } from "next-intl";
import ProductList from "../product/product-list";
import {
  useGetRandomProducts,
} from "src/api/product";
import { useCallback, useEffect, useRef, useState } from "react";
import HomeFeaturedAdditionalItems from "./home-featured-additional-items";
import { useResponsive } from "src/hooks/use-responsive";

const HomeFeatured = () => {
  const t = useTranslations();
  const [excludeIds, setExcludeIds] = useState<string[] | undefined>(undefined);
  const [itemsLoaded, setItemsLoaded] = useState<boolean>(false);
  const { products, productsLoading, pageCount, currentPage, productsEmpty } =
    useGetRandomProducts({ isSmall: false });
  const handleScroll = useCallback(() => {
    if (
      !itemsLoaded &&
      loadingRef.current &&
      window.innerHeight + window.scrollY >= loadingRef.current?.offsetTop
    ) {
      setItemsLoaded(true);
      setExcludeIds(products.map((product) => product.id.toString()));
    }
  }, []);

  const loadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const betweenMdLg = useResponsive("between", "md", "lg");

  return (
    <Container maxWidth={"lg"} sx={{ mt: 4, mb: 8 }}>
      <Typography textAlign="center" variant="h4" sx={{ mb: 6 }}>
        {t("featured-products")}
      </Typography>
      <ProductList
        products={betweenMdLg ? products.slice(0, 18) : products}
        setCurrentClientPage={() => {
          /* Disabled pagination*/
        }}
        disablePagination
        disableScrollToTop
      />
      {excludeIds !== undefined ? (
        <HomeFeaturedAdditionalItems excludeIds={excludeIds} />
      ) : (
        <Box
          sx={{ my: 8, mx: "auto", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress sx={{ mx: "auto !important", mt: 3 }} ref={loadingRef} />
        </Box>
      )}
    </Container>
  );
};
export default HomeFeatured;
