"use client";
import ProductList from "../product/product-list";
import { useGetRandomProducts } from "src/api/product";

const HomeFeaturedAdditionalItems = ({excludeIds}: {excludeIds: string[]}) => {
  const { products, productsLoading, pageCount, currentPage, productsEmpty } = useGetRandomProducts({ isSmall: false, excludeIds: excludeIds });

  return (
      <ProductList
        sx={{mt: {xs: 1, sm: 2, md: 3}}}
        products={products}
        setCurrentClientPage={() => {
          /* Disabled pagination*/
        }}
        disablePagination
        disableScrollToTop
      />
  );
};
export default HomeFeaturedAdditionalItems;
