import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.NEXT_PUBLIC_API_URL;
export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY;
export const INPOST_API_KEY = process.env.NEXT_PUBLIC_INPOST_API_KEY;
export const INPOST_GEOWIDGET_KEY = process.env.NEXT_PUBLIC_INPOST_GEOWIDGET_KEY;
export const ASSETS_API = process.env.NEXT_PUBLIC_ASSETS_API;
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
