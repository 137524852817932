import { _id } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------
const MOCK_ID = _id[1];
export const paths = {
  contact: '/contact',
  product: {
    root: `/products`,
    checkout: `/products/checkout`,
    new: `/products/new`,
    details: (id: string) => `/products/${id}`,
    edit: (id: string) => `/products/${id}/edit`,
    demo: {
      details: `/products/${MOCK_ID}`,
    },
  },
  legal: {
    termsOfServices: `/terms-of-services`,
    cookiesPolicy: `/cookies-policy`,
    privacyPolicy: `/privacy-policy`,
  },
  home: `/home`,
  lighting: `/lighting`,
  kitchen: `/kitchen`,
  kids: `/kids`,
  animals: `/animals`,
  garden: `/garden`,
  gifts: `/gifts`,
  christmasDecorations: `/christmas-decorations`,
};
