'use client'

import { m } from "framer-motion";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { RouterLink } from "src/routes/components";

import CompactLayout from "src/layouts/compact";
import { PageNotFoundIllustration } from "src/assets/illustrations";

import { varBounce, MotionContainer } from "src/components/animate";
import { Box, Container, Link, Stack } from "@mui/material";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";

// ----------------------------------------------------------------------

export default function NotFoundView() {
  const t = useTranslations();
  return (
    <Container>
      <Stack
        display="flex"
        flexDirection="column"
        alignItems="center"
        spacing={0}
      >
        <MotionContainer mt={10}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" sx={{ mb: 2 }} textAlign="center">
              {t("page-not-found")}
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: "text.secondary" }} textAlign="center">
              {t("page-not-found-description")}
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <PageNotFoundIllustration
              sx={{
                height: 260,
                my: { xs: 5, sm: 10 },
              }}
            />
          </m.div>
        </MotionContainer>
        <Link
          component={RouterLink}
          href="/"
          sx={{ mb: 8 }}
          variant="overline"
        >
          {t("go-to-home")}
        </Link>
      </Stack>
    </Container>
  );
}
